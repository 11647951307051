<template>
  <div id="monthlyReportUploadModal" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog" style="transition: all 0.3s">
      <div class="modal-content bg-default-dark border-text-default-dark">
        <div class="modal-header align-items-center">
          <h5 class="modal-title mb-0 text-nowrap">Upload Monthly Report</h5>
        </div>
        <div class="modal-body">
          <div v-if="formData.file" class="table-responsive">
            <div class="badge badge-light rounded-pill px-3 py-2 mb-3">
              (<sup class="text-danger">*</sup>) is required field
            </div>

            <table class="table table-bordered mb-0">
              <thead class="table-dark">
                <tr>
                  <th>File Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input class="form-control form-control-sm" v-model="formData.name" type="text">
                  </td>
                  <td class="text-center w-1">
                    <button class="btn btn-danger btn-sm text-nowrap" @click="clearFile">
                      <i class="fas fa-times mr-1"></i>
                      Remove
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <file-drop note="Accepted format: .pdf" :accept="fileAcceptedFormats" @selected="fileSelected" v-else />
        </div>

        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-secondary text-light" data-bs-dismiss="modal" @click="closeModal()">
            Cancel
          </button>
          <div v-if="formData.file">
            <button class="btn btn-primary text-uppercase" @click="handleSubmit" :disabled="formInvalid">
              <i class="fas fa-exclamation-triangle text-danger mr-2" v-if="formInvalid" />
              Upload Monthly Report
            </button>
          </div>
        </div>
      </div>
    </div>

    <spinner :show="isSaving" :text="`${isSavingText || 'Uploading file...'}`" />
  </div>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";
import FileDrop from "@/components/FileDrop";
import Spinner from "@/components/Spinner";

export default {
  name: "modal-monthly-report-upload",
  components: { Spinner, FileDrop },
  data() {
    return {
      formData: {
        name: "",
        file: null,
      },
      emptyFormData: {
        name: "",
        file: null,
      },
      requiredData: ["name", "file"],
      isSaving: false,
      isSavingText: null,
      fileAcceptedFormats: ["application/pdf"],
    };
  },
  computed: {
    formInvalid() {
      let value = false;

      this.requiredData.forEach(key => {
        if (!this.formData[key]) value = true;
      });

      return value;
    },
  },
  methods: {
    clearFile() {
      this.$set(this, 'formData', { ...this.emptyFormData });
    },
    fileSelected(e) {
      this.formData.file = e;
      this.formData.name = e.name;
    },
    async handleSubmit() {
      if (!this.formInvalid) {
        this.isSaving = true;

        this.isSavingText = "Uploading file";

        let result = null;

        try {
          const fd = new FormData();

          let url = "monthly-reports";

          fd.append("files.file", this.formData.file, this.formData.name);
          fd.append('data', JSON.stringify({ name: this.formData.name }));

          const [call, err] = await this.Helper.handle(
            this.API.postForm(url, fd)
          );

          result = call.data[0];
        } catch (error) {
          this.isSavingText = "Error";

          Swal.fire({
            title: "<h5>Error</h5>",
            html: `<div class='line-height-md text-light'>${error}</div>`,
            icon: "error",
          });
        }

        if (this.isSavingText !== "Error") {
          Swal.fire({
            title: `<h5 class='text-uppercase'>Monthly report uploaded</h5>`,
            icon: "success",
          }).then(() => {
            this.closeModal(true);
          });
        }

        this.isSaving = false;

        this.isSavingText = null;
      }
    },
    closeModal(reload) {
      this.$emit("close");

      if (reload) {
        this.$emit("reload");
      }
    },
  },
  mounted() {
    const vm = this;

    $("#monthlyReportUploadModal").on("hidden.bs.modal", function (event) {
      vm.clearFile();
    });
  },
  beforeDestroy() {
    $("monthlyReportUploadModal").off("hidden.bs.modal");
  },
};
</script>