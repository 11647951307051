<template>
  <div class="view-fullscreen has-navbar has-footer position-relative layout-split-side">
    <div class="side-right p-3">
      <div class="px-3 py-3 pb-4 d-flex justify-content-between align-items-center">
        <h4 class="text-uppercase font-weight-bold mb-0">Monthly Reports</h4>
        <button class="btn btn-dark border-secondary" @click="showUploadModal = true">
          <i class="fas fa-plus mr-1"></i>
          Upload file
        </button>
      </div>

      <div class="table-responsive px-3">
        <table class="table table-bordered table-dark">
          <thead>
            <tr>
              <th class="w-1">No</th>
              <th>Name</th>
              <th>File</th>
              <th class="w-1 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(file, index) in files" :key="file.id" class="align-middle">
              <td class="w-1">{{ index + 1 }}</td>
              <td class="text-nowrap">{{ file.name.replace('.pdf', '') }}</td>
              <td>
                {{ file.file.name }}
              </td>
              <td class="w-1 text-center text-nowrap">
                <button class="btn btn-sm btn-primary mx-1" @click="currentFile = file.file">
                  <i class="fas fa-eye"></i>
                </button>
                <button class="btn btn-sm btn-danger mx-1" @click="deleteFile(file)">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
            <tr v-if="!files.length">
              <td colspan="6" class="text-center font-italic text-secondary">
                No file found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <monthly-report-upload-modal @close="showUploadModal = false" @reload="reloadList" />
    <media-preview v-if="currentFile" :title="currentFile.name" :media="currentFile" :hasNavigation="false"
      @close="currentFile = null" />
    <spinner :show="isLoading" />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Spinner from "@/components/Spinner.vue";
import MediaPreview from "@/components/MediaPreview";
import MonthlyReportUploadModal from "@/components/MonthlyReportUploadModal";

export default {
  name: "reports",
  components: {
    Spinner,
    MediaPreview,
    MonthlyReportUploadModal
  },
  data() {
    return {
      isLoading: false,
      files: [],
      currentFile: null,
      showUploadModal: false
    };
  },
  watch: {
    showUploadModal: {
      handler() {
        if (this.showUploadModal) {
          return this.Helper.showModal("#monthlyReportUploadModal");
        } else {
          this.Helper.hideModal("#monthlyReportUploadModal");
        }
      },
    }
  },
  methods: {
    reloadList() {
      this.getFiles();
    },
    async getFiles() {
      this.isLoading = true;

      const [call, err] = await this.Helper.handle(
        this.API.get(`monthly-reports?_sort=createdAt:desc`)
      );

      if (!err && call.status == 200) {
        this.files = call.data;
      }

      this.currentFile = null;

      this.isLoading = false;
    },
    async deleteFile(file) {
      Swal.fire({
        title: `<h5 class='mb-0'>Delete ${file.name.toUpperCase()}?</h5>`,
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        confirmButtonColor: "red",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoading = true;

          const [call, err] = await this.Helper.handle(
            this.API.del(`monthly-reports/${file.id}`)
          );

          if (!err && call.status == 200) {
            this.reloadList();
          }

          this.currentFile = null;

          this.isLoading = false;
        }
      });

    },
  },
  mounted() {
    this.getFiles();
  }
};
</script>